import * as React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { ThemedApp } from './theme/theme'; // Import ThemedApp instead of individual imports
import AppAppBar from './components/LandingPage/AppAppBar';
import Hero from './components/LandingPage/Hero';
import Pricing from './components/LandingPage/Pricing';
import PlatformGallery from './components/LandingPage/PlatformGallery';
import Divider from '@mui/material/Divider';
import Footer from './components/LandingPage/Footer';

export default function Pyxelio() {
  return (
    <ThemedApp>
      <CssBaseline />
      <AppAppBar />
      <Hero/>
      <Divider />
      <PlatformGallery />
      <Divider />
      <Pricing />
      <Divider />
      <Footer />
    </ThemedApp>  
    );
}
