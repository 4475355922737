import React from 'react';
import { Box, Container, Stack, Typography, styled, keyframes } from '@mui/material';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import image1 from '../../assets/robot1.jpg';
import image2 from '../../assets/robot2.png';
import AnimatedButton from './AnimatedButton'; // Correct import statement
import { Theme } from '@mui/material/styles';

// StyledBox component for the image comparison slider
const StyledBox = styled('div')(({ theme }: { theme: Theme }) => ({
  alignSelf: 'center',
  width: '100%',
  maxWidth: '550px', // Reduced maxWidth
  height: 'auto',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  border: '1px solid transparent',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
  ...(theme.palette.mode === 'dark' && {
    boxShadow: '0 0 24px 12px hsla(270, 100%, 25%, 0.2)',
    borderColor: theme.palette.grey[700],
  }),
}));

// Keyframes for the gradient shuffle animation
const gradientShuffle = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

// Styled typography component for gradient text with shuffle effect
const GradientText = styled('span')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  backgroundSize: '200% 200%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientShuffle} 4s linear infinite`,
  fontWeight: 'bold',
}));

/**
 * Hero component displays a title, subtitle, and a comparison slider with images.
 */
export default function Hero() {
  return (
    <Box>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 10 },
          pb: { xs: 8, sm: 0 },
        }}
      >
        <Stack
          spacing={3}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              color: '#FFFFFF',
            }}
          >
            Pixel&nbsp;Art&nbsp;
            <GradientText>Converter</GradientText>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: '#FFFFFF',
              width: { sm: '100%', md: '80%' },
              fontSize: 'clamp(2rem, 1vw, 3.5rem)',
            }}
          >
            Photo-to-Pixel Transformation
          </Typography>
          <AnimatedButton>
            Generate
          </AnimatedButton>
        </Stack>
        <StyledBox id="image">
          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src={image1}
                alt="Before"
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center center',
                  width: '100%',
                  height: 'auto',
                }}
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={image2}
                alt="After"
                style={{
                  objectFit: 'contain',
                  objectPosition: 'center center',
                  width: '100%',
                  height: 'auto',
                }}
              />
            }
            style={{ width: '100%', height: 'auto' }}
          />
        </StyledBox>
      </Container>
    </Box>
  );
}
