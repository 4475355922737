import * as React from 'react';
import { Container, Typography, Box, Card, CardMedia, CardContent, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import img1 from '../../assets/1.jpg';
import img2 from '../../assets/2.jpg';
import img3 from '../../assets/3.jpg';
import img4 from '../../assets/4.jpg';
import img5 from '../../assets/5.png';
import img6 from '../../assets/6.png';

// Sample images array with varying sizes
const images = [
  { src: img1, alt: 'Pixel Art 1', title: 'Pixel Art 1', size: 'large' },
  { src: img2, alt: 'Pixel Art 2', title: 'Pixel Art 2', size: 'medium' },
  { src: img3, alt: 'Pixel Art 3', title: 'Pixel Art 3', size: 'small' },
  { src: img4, alt: 'Pixel Art 4', title: 'Pixel Art 4', size: 'large' },
  { src: img5, alt: 'Pixel Art 5', title: 'Pixel Art 5', size: 'medium' },
  { src: img6, alt: 'Pixel Art 6', title: 'Pixel Art 6', size: 'small' },
];

// Keyframes for the gradient shuffle animation
const gradientShuffle = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

// Styled typography component for gradient text
const GradientText = styled('span')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  backgroundSize: '200% 200%',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: `${gradientShuffle} 4s linear infinite`,
  fontWeight: 'bold',
}));

interface PlatformGalleryProps {
  backgroundOpacity?: number;
}

export default function PlatformGallery({ backgroundOpacity = 0.6 }: PlatformGalleryProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative', // Ensure the overlay is positioned relative to this container
        pt: { xs: 4, sm: 3 },
        pb: { xs: 4, sm: 12 },
      }}
    >
      {/* Overlay for the gallery */}
      <Box
        sx={{
          position: 'absolute',
          top: -20,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: `rgba(20, 10, 40, ${1 - backgroundOpacity})`,
          zIndex: 0,
        }}
      />

      <Container
        sx={{
          position: 'relative',
          zIndex: 1, // Ensure content is above the overlay
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mb: 4,
            textAlign: 'center',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          <span style={{ color: 'white' }}>Pixel Art</span>{' '}
          <GradientText>Gallery</GradientText>
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 2,
            width: '100%',
            maxWidth: '1200px',
            gridAutoRows: 'minmax(200px, auto)',
            position: 'relative',
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: `rgba(${theme.palette.background.default.replace(
              /rgb\((\d+),\s*(\d+),\s*(\d+)\)/,
              '$1,$2,$3'
            )}, ${backgroundOpacity})`,
          }}
        >
          {images.map((image, index) => (
            <Card
              key={index}
              sx={{
                gridRowEnd: image.size === 'large' ? 'span 2' : 'span 1',
                gridColumnEnd: image.size === 'large' ? 'span 2' : 'span 1',
                overflow: 'hidden',
                borderRadius: 0,
                boxShadow: theme.shadows[3],
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: theme.shadows[6],
                },
              }}
            >
              <CardMedia
                component="img"
                image={image.src}
                alt={image.alt}
                sx={{
                  height: '100%',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
              <CardContent>
                <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>
                  {image.title}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
