import React from 'react';
import { AppBar, Toolbar, IconButton, Button, Typography, useTheme, alpha } from '@mui/material';
import { Brush as BrushIcon, CloudUpload as CloudUploadIcon, Settings as SettingsIcon } from '@mui/icons-material';
import SitemarkIcon from './SitemarkIcon'; // Import your SitemarkIcon

const AppAppBar: React.FC = () => {
  const theme = useTheme();

  return (
    <AppBar 
      position="static" 
      sx={{
        background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.8)} 30%, ${alpha(theme.palette.secondary.main, 0.8)} 90%)`,
        backdropFilter: 'blur(10px)',
        boxShadow: 'none',
        padding: '0 16px', // Ensure padding for consistency with the marketing page layout
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
            <SitemarkIcon sx={{ fontSize: 40 }} /> {/* Increase the size of the icon */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', letterSpacing: '1px' }}>
            Pyxelio
          </Typography>
        </div>
        <div>
          <Button color="inherit" startIcon={<BrushIcon />} sx={{ mr: 1 }}>
            Generate
          </Button>
          <Button color="inherit" startIcon={<CloudUploadIcon />} sx={{ mr: 1 }}>
            Sign In
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppAppBar;
