import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// Pricing tiers for pixel art generation service
const tiers = [
  {
    title: 'Basic Plan',
    price: '0',
    description: [
      '15 credits per month',
      'Standard resolution pixel art',
    ],
    buttonText: 'Sign Up for Free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Premium Plan',
    subheader: 'Recommended',
    price: '25',
    description: [
      '1000 credits per month',
      'High resolution pixel art',
      'Priority support',
    ],
    buttonText: 'Go Premium',
    buttonVariant: 'contained',
  },
  {
    title: 'Business Plan',
    price: '49',
    description: [
      '3500 credits per month',
      'High resolution pixel art',
      'Priority support',
    ],
    buttonText: 'Start Now',
    buttonVariant: 'outlined',
  },
];

// Custom pink and purple color palette
const customColors = {
  pink: {
    light: '#FFFFFF',
    main: '#6D49FF',
    dark: '#C71585',
  },
  purple: {
    light: '#FFF6FA',
    main: '#4c1d95',
    dark: '#4B0082',
  },
};

export default function Pricing() {
  const theme = useTheme();

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 20 },
        pb: { xs: 8, sm: 20 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: customColors.pink.light }}
        >
          Plans & Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: customColors.purple.light }}>
          Choose the perfect plan to turn your photos into stunning pixel art:
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === 'Business Plan' ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(30, 27, 75, 0.8)' : 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: `0 12px 24px ${theme.palette.mode === 'dark' ? 'rgba(255, 105, 180, 0.3)' : 'rgba(147, 112, 219, 0.3)'}`,
                  },
                },
                tier.title === 'Premium Plan' && {
                  background: `linear-gradient(145deg, ${customColors.pink.main}, ${customColors.purple.main})`,
                  boxShadow: `0 8px 16px ${theme.palette.mode === 'dark' ? 'rgba(255, 105, 180, 0.4)' : 'rgba(147, 112, 219, 0.4)'}`,
                },
              ]}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    color: tier.title === 'Premium Plan' ? customColors.purple.light : customColors.pink.light,
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Premium Plan' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      sx={{
                        backgroundColor: customColors.purple.light,
                        color: customColors.purple.dark,
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Premium Plan' ? customColors.purple.light : customColors.pink.light,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: customColors.purple.light }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: tier.title === 'Premium Plan' ? customColors.purple.light : customColors.pink.light,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      component={'span'}
                      sx={{
                        color: tier.title === 'Premium Plan' ? customColors.purple.light : customColors.pink.light,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  sx={{
                    color: tier.title === 'Premium Plan' && tier.buttonVariant === 'contained'
                      ? customColors.purple.dark
                      : customColors.pink.light,
                    borderColor: customColors.pink.light,
                    backgroundColor: tier.title === 'Premium Plan' && tier.buttonVariant === 'contained'
                      ? customColors.purple.light
                      : 'transparent',
                    '&:hover': {
                      backgroundColor: tier.title === 'Premium Plan' && tier.buttonVariant === 'contained'
                        ? customColors.purple.main
                        : 'rgba(255, 105, 180, 0.1)',
                      color: tier.title === 'Premium Plan' && tier.buttonVariant === 'contained'
                        ? customColors.purple.light
                        : customColors.pink.light,
                    },
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
