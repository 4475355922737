import * as React from 'react';
import { styled, keyframes } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

// Keyframes for the smoother gradient animation
const gradientAnimation = keyframes`
  0% {
    stop-color: #4c1d95;
  }
  50% {
    stop-color: #4A90E2;
  }
  100% {
    stop-color: #4c1d95;
  }
`;

// Styled SVG Icon with animated gradient
const AnimatedSvgIcon = styled(SvgIcon)(({ theme }) => ({
  width: 48,
  height: 48,
  '& svg': {
    width: '100%',
    height: '100%',
  },
  // Adding CSS for gradient animation
  '& .gradient-stop1': {
    animation: `${gradientAnimation} 6s ease-in-out infinite`,
  },
  '& .gradient-stop2': {
    animation: `${gradientAnimation} 6s ease-in-out infinite`,
    animationDelay: '3s', // To create a smoother gradient transition effect
  },
}));

export default function PixelPIcon() {
  return (
    <AnimatedSvgIcon viewBox="0 0 24 24">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        {/* Gradient Definition */}
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" className="gradient-stop1" stopColor="#4A90E2" />
            <stop offset="100%" className="gradient-stop2" stopColor="#ECF0F1" />
          </linearGradient>
        </defs>
        
        {/* Background */}
        <rect x="0" y="0" width="24" height="24" fill="#F0F0F0" />

        {/* Pixelated 'P' with gradient colors */}
        <rect x="2" y="2" width="20" height="20" fill="url(#gradient1)" />
        <rect x="4" y="4" width="16" height="16" fill="url(#gradient1)" />
        <rect x="6" y="6" width="12" height="12" fill="url(#gradient1)" />
        
        {/* 'P' shape in a modern style */}
        <rect x="8" y="8" width="6" height="2" fill="#ECF0F1" />
        <rect x="8" y="10" width="2" height="6" fill="#ECF0F1" />
        <rect x="10" y="12" width="4" height="2" fill="#ECF0F1" />
        <rect x="12" y="10" width="2" height="2" fill="#ECF0F1" />

        {/* Accent squares for a modern touch */}
        <rect x="16" y="16" width="2" height="2" fill="#3498DB" />
        <rect x="18" y="16" width="2" height="2" fill="#3498DB" />
        <rect x="16" y="18" width="2" height="2" fill="#3498DB" />
        <rect x="18" y="18" width="2" height="2" fill="#3498DB" />
      </svg>
    </AnimatedSvgIcon>
  );
}
