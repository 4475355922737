import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createTheme, ThemeProvider, styled, keyframes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Define color palettes
const brand = {
  50: 'hsl(210, 100%, 95%)',
  100: 'hsl(210, 100%, 92%)',
  200: 'hsl(210, 100%, 80%)',
  300: 'hsl(210, 100%, 65%)',
  400: 'hsl(210, 98%, 48%)',
  500: 'hsl(210, 98%, 42%)',
  600: 'hsl(210, 98%, 55%)',
  700: 'hsl(210, 100%, 35%)',
  800: 'hsl(210, 100%, 16%)',
  900: 'hsl(210, 100%, 21%)',
};

const gray = {
  50: 'hsl(220, 35%, 97%)',
  100: 'hsl(220, 30%, 94%)',
  200: 'hsl(220, 20%, 88%)',
  300: 'hsl(220, 20%, 80%)',
  400: 'hsl(220, 20%, 65%)',
  500: 'hsl(220, 20%, 42%)',
  600: 'hsl(220, 20%, 35%)',
  700: 'hsl(220, 20%, 25%)',
  800: 'hsl(220, 30%, 6%)',
  900: 'hsl(220, 35%, 3%)',
};

// Theme options generator
const getDesignTokens = (mode: 'light' | 'dark') => ({
  palette: {
    mode,
    primary: {
      light: brand[200],
      main: brand[400],
      dark: brand[700],
      contrastText: brand[50],
      ...(mode === 'dark' && {
        contrastText: brand[50],
        light: brand[300],
        main: brand[400],
        dark: brand[700],
      }),
    },
    background: {
      default: 'transparent',
      paper: mode === 'dark' ? 'hsl(220, 30%, 7%)' : 'hsl(220, 35%, 97%)',
    },
    text: {
      primary: mode === 'dark' ? 'hsl(0, 0%, 100%)' : gray[800],
      secondary: mode === 'dark' ? gray[400] : gray[600],
    },
  },
  typography: {
    fontFamily: ['"Inter", "sans-serif"'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

// Keyframes for animations
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
`;

const twinkle = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
`;

// Styled components for animations
const PulseAnimation = styled('div')`
  width: 16rem;
  height: 16rem;
  background: linear-gradient(to bottom right, #60a5fa, #a855f7);
  border-radius: 50%;
  filter: blur(64px);
  opacity: 0.2;
  animation: ${pulse} 6s infinite;
`;

const TwinkleStar = styled('div')<{ $isFadingOut: boolean }>`
  position: absolute;
  border-radius: 50%;
  background-color: white;
  animation: ${twinkle} 6s infinite;
  opacity: ${({ $isFadingOut }) => ($isFadingOut ? 0 : 1)};
  transition: opacity 2s ease-out;
`;

// Helper function to generate a star
const generateStar = (id: number) => ({
  id,
  top: `${Math.random() * 100}%`,
  left: `${Math.random() * 100}%`,
  size: `${Math.random() * 3 + 1}px`,
  delay: `${Math.random() * 6}s`,
  isFadingOut: false,
});

// Modern Futuristic Background component
const ModernFuturisticBackground: React.FC<{ overlayOpacity?: number }> = ({ overlayOpacity = 0.5 }) => {
  const [stars, setStars] = useState<Array<{
    id: number;
    top: string;
    left: string;
    size: string;
    delay: string;
    isFadingOut: boolean;
  }>>([]);
  const maxStars = 20; // Maximum number of stars
  const starIdRef = useRef(0); // Ref to track star IDs
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const addStar = () => {
    setStars(prevStars => {
      if (prevStars.length >= maxStars) {
        const updatedStars = prevStars.map(star => ({
          ...star,
          isFadingOut: star.isFadingOut ? false : star.isFadingOut,
        }));
        return [...updatedStars.filter(star => !star.isFadingOut).slice(1), generateStar(starIdRef.current++)];
      } else {
        return [...prevStars, generateStar(starIdRef.current++)];
      }
    });
  };

  const startFadingOutStars = () => {
    setStars(prevStars => {
      const starToFadeOut = prevStars.find(star => !star.isFadingOut);
      if (starToFadeOut) {
        return prevStars.map(star =>
          star.id === starToFadeOut.id
            ? { ...star, isFadingOut: true }
            : star
        );
      }
      return prevStars;
    });
  };

  useEffect(() => {
    addStar();

    intervalRef.current = setInterval(() => {
      startFadingOutStars();

      setTimeout(() => {
        setStars(prevStars => prevStars.map(star =>
          star.isFadingOut
            ? generateStar(star.id) // Replace with new position
            : star
        ));
      }, 9000); // Delay matches fade-out duration

      addStar(); // Add new star after some time
    }, 6000); // Interval for fading out and adding stars

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      zIndex: -1,
    }}>
      <div style={{
        position: 'absolute',
        inset: 0,
        background: 'linear-gradient(to bottom right, #1e1b4b, #4c1d95, #831843)',
      }} />
      <div style={{
        position: 'absolute',
        inset: 0,
        opacity: 0.3,
      }}>
        <svg style={{ width: '100%', height: '100%' }} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
              <path d="M 40 0 L 0 0 0 40" fill="none" stroke="rgba(255,255,255,0.3)" strokeWidth="2" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
      </div>
      <div style={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <PulseAnimation />
      </div>
      <div style={{ position: 'absolute', inset: 0 }}>
        {stars.map(star => (
          <TwinkleStar
            key={star.id}
            style={{
              top: star.top,
              left: star.left,
              width: star.size,
              height: star.size,
              animationDelay: star.delay,
            }}
            $isFadingOut={star.isFadingOut}
          />
        ))}
      </div>
      {/* Overlay layer */}
      <div style={{
        position: 'absolute',
        inset: 0,
        backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
        zIndex: 1,
      }} />
    </div>
  );
};

// Themed app wrapper with Modern Futuristic Background
export const ThemedApp: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const theme = useMemo(() => createTheme(getDesignTokens('light')), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ModernFuturisticBackground overlayOpacity={0.5} />
      {children}
    </ThemeProvider>
  );
};
