// src/components/LandingPage/AnimatedButton.tsx
import { styled, keyframes } from '@mui/material/styles';
import { Button } from '@mui/material';

// Keyframes for the gradient animation
const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

// Styled button with animated gradient
const AnimatedButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: '12px 24px',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  background: 'transparent',
  color: theme.palette.primary.contrastText,
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
  overflow: 'hidden',
  outline: 'none',
  transition: 'color 0.4s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundSize: '200% 200%',
    zIndex: -1,
    animation: `${gradientAnimation} 4s linear infinite`,
  },
  '&:hover': {
    color: theme.palette.primary.main,
    '&::before': {
      backgroundPosition: '100% 0%',
    },
  },
}));

export default AnimatedButton;
