import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SitemarkIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 240 240">
      {/* Gradient Definition */}
      <defs>
        <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#4A90E2" />
          <stop offset="100%" stopColor="#4c1d95" />
        </linearGradient>
      </defs>
      
      {/* Background */}
      <rect x="0" y="0" width="240" height="240" fill="#F0F0F0" />
      
      {/* Pixelated 'P' with gradient colors */}
      <rect x="20" y="20" width="200" height="200" fill="url(#gradient1)" />
      <rect x="40" y="40" width="160" height="160" fill="url(#gradient1)" />
      <rect x="60" y="60" width="120" height="120" fill="url(#gradient1)" />
      
      {/* 'P' shape in a modern style */}
      <rect x="80" y="80" width="60" height="20" fill="#ECF0F1" />
      <rect x="80" y="100" width="20" height="60" fill="#ECF0F1" />
      <rect x="100" y="120" width="40" height="20" fill="#ECF0F1" />
      <rect x="120" y="100" width="20" height="20" fill="#ECF0F1" />
      
      {/* Accent squares for a modern touch */}
      <rect x="160" y="160" width="20" height="20" fill="#3498DB" />
      <rect x="180" y="160" width="20" height="20" fill="#3498DB" />
      <rect x="160" y="180" width="20" height="20" fill="#3498DB" />
      <rect x="180" y="180" width="20" height="20" fill="#3498DB" />
    </SvgIcon>
  );
}

export default SitemarkIcon;
